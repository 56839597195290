/* @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap'); */



/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap'); */
/* * {
    font-family: 'Nunito Sans';
} */
.macau_banner_slider .slick-dots {
    position: absolute !important;
    /* bottom: 50px; */
    bottom: 25px;
}

.macau_banner_slider .slick-dots li button::before {
    content: "";
    border: 2px solid #000;
    background: #fff;

}

.gb_shap_item_margin {
    margin: 0 15px;
}

.macau_banner_slider div {
    outline: none;
}

.bannerSliderHome.macau_banner_slider button.slick-arrow.slick-prev {
    z-index: 9;
    left: 15px;
}

.bannerSliderHome.macau_banner_slider button.slick-arrow.slick-prev {
    width: 42px;
    height: 42px;
    opacity: 1;
    left: 0px;
    border-radius: 0px;
    color: #000 !important;
    background: rgba(255, 255, 255, 0.5) !important;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    border: 1px solid #ddd !important;
}

.bannerSliderHome.macau_banner_slider button.slick-arrow.slick-prev::before {
    background: url('../../images/left-arrow.png');
    background-repeat: no-repeat !important;
    bottom: 0;
    content: "";
    height: 35px;
    left: -3px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 4px;
    width: 25px;
}

.bannerSliderHome.macau_banner_slider button.slick-arrow.slick-next {
    width: 42px;
    height: 42px;
    opacity: 1;
    right: 0px;
    border-radius: 0px;
    color: #000 !important;
    background: rgba(255, 255, 255, 0.5) !important;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    border: 1px solid #ddd !important;
}

.bannerSliderHome.macau_banner_slider button.slick-arrow.slick-next::before {
    background: url('../../images/right-arrow.png');
    background-repeat: no-repeat !important;
    bottom: 0;
    content: "";
    left: 8px;
    margin: auto;
    position: absolute;
    right: 0px;
    top: 8px;
    background-size: 76%;
}

.macau_banner_slider .slick-dots li button::before {
    content: "";
    border: 4px solid #222 !important;
    background: #fff;
    border-radius: 100%;
    height: 12px;
    width: 12px;
    opacity: 1;
}

.macau_banner_slider .slick-dots li button {
    padding: 0px !important;
}

.macau_banner_slider .slick-dots li {
    height: 12px;
    width: 12px;
}

.banner-slider .owl-stage .owl-item .item {
    position: relative;
}

.banner-slider .owl-stage img {
    max-width: 100%;
    height: 620px;
    object-fit: cover;
}

.banner-slider .owl-stage .content {
    position: absolute;
    top: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.banner-slider .owl-stage .content h2 {
    font: 400 55px/normal 'Great Vibes' !important;
}

.banner-slider .owl-stage .content p {
    font-size: 18px;
}

.solid-black {
    background: #000 !important;
    color: #FFF !important;
    border-radius: 26px;
    padding: 11px 25px;
    min-width: 200px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-top: 25px;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -ms-transition: all .5s;
    -webkit-transition: all .5s;
    font-weight: 400;
}

.owl-dots {
    text-align: center;
    position: absolute;
    bottom: 7%;
    left: 0;
    right: 0;
}

.owl-dots button {
    border: 4px solid #222 !important;
    padding: 2px !important;
    background: #fff !important;
    border-radius: 50%;
    margin-right: 4px;
}

.owl-dots button.active span {
    background: #ba933e !important;
}

.owl-dots .owl-dot span {
    width: 4px !important;
    height: 4px !important;
    margin: 0 !important;
    background: #D6D6D6 !important;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 100%;
}

.owl-nav button {
    width: 42px;
    height: 42px;
    display: inline-block;
    opacity: 1;
    padding: 0;
    text-align: center;
    margin: -25px 0 0 !important;
    border-radius: 0px !important;
    position: absolute;
    top: 50%;
    z-index: 9;
    color: #000 !important;
    background: rgba(255, 255, 255, 0.5) !important;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    border: 1px solid #ddd !important;
}

.testimonials .owl-nav button {
    margin-top: 20px !important;
}

.owl-nav .owl-prev {
    left: 0px;
}

.owl-nav .owl-next {
    right: 0px;
}

.create_own_diamond_ring {
    margin: 50px 0 0;
    padding: 0;
}

.create_own_diamond_ring .right-content h2 {
    font-size: 32px;
    margin-bottom: 15px;
    font-family: 'Nunito Sans' !important;
}

.create_own_diamond_ring .right-content p {
    font-size: 16px;
    font-weight: 400;
}

/* .create_own_diamond_ring .row {
    margin: 50px auto 0;
    width: 90%;
} */

.outline-btn {
    transition: 0.4s all ease-in-out;
    /* width: 57%; */
    margin-top: 20px;
    /* padding: 10px 0px; */
    /* width: 277px; */
    padding: 11px 4px;
    padding: 10px 25px;
    border: 1px solid #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.42857143;
    border-radius: 1px;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.outline-btn:hover {
    background-color: var(--navyBlue);
    border-color: var(--navyBlue);
    color: #fff;
}

.ny_btnleft {
    margin-right: 21px;
}

.vert-move {
    animation: mover 1.5s infinite alternate;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

.our_collection {
    background: #fbf8f3;
    padding: 50px 0 130px;
}

.title-box h2 {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 15px;
}

.collections .banner-block {
    width: 470px;
    display: inline-block;
}

.collections .block-1 {
    margin-right: 230px;
}

.collections .banner-box {
    position: relative;
}

.collections .banner-box a:before {
    position: absolute;
    display: inline-block;
    top: 50px;
    right: -105px;
    width: 370px;
    height: 244px;
    content: '';
    transition: .7s all ease;
    border: 1px solid #f7ebd8 !important;
    z-index: 1;
    pointer-events: none;
}

.collections .banner-box .s-desc {
    padding: 85px 40px 30px 47px;
    min-height: 325px;
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 10%);
    background: hsla(0, 0%, 100%, .8) !important;
    z-index: 9;
}

.collections .banner-box .s-desc h3 {
    font-weight: 100;
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 26px;
    display: inline-block;
    /* font-family: 'Cinzel' !important; */
    text-transform: uppercase;
    color: #333;
    position: relative;
}

.collections .banner-box .s-desc h3:before {
    content: '';
    position: absolute;
    display: inline-block;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #d9cac9;
    transition: .5s all ease;
}

.collections .banner-box .s-desc p {
    padding-right: 80px;
    color: #333;
    letter-spacing: .4px;
    font-size: 16px;
    font-weight: 400;
}

.collections .banner-box img {
    position: absolute;
    top: -10px;
    right: -270px;
    pointer-events: painted;
    transition: 1s all ease;
    z-index: 10;
}

.collections .banner-box a:hover {
    color: #8f8180;
}

.collections .block-1 .banner-box a:hover img {
    transform: translateX(2%) translateY(-2%);
}

.collections .block-2 {
    margin-top: 123px;
}

.collections .block-2 .s-desc {
    padding-left: 60px;
    padding-right: 55px;
    min-height: 428px;
}

.collections .block-2 .banner-box a:before {
    top: auto;
    bottom: -160px;
    left: 60px;
    width: 350px;
    height: 330px;
}

.collections .block-2 .banner-box img {
    right: auto;
    left: 10px;
    bottom: -255px;
    top: auto;
}

.collections .block-2 .banner-box a:hover img {
    transform: translateY(2%);
}

.collections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.collections .block-3 .banner-box .s-desc {
    padding-left: 42%;
}

.collections .block-3 .banner-box a:before {
    top: 40px;
    left: -100px;
    width: 270px;
    height: 245px;
}

.collections .block-3 .banner-box a img {
    top: -65px;
    left: -248px;
}

.collections .block-3 .banner-box a:hover img,
.collections .block-4 .banner-box a:hover img,
.collections .block-5 .banner-box a:hover img {
    transform: translateX(2%) translateY(-2%);
}

.collections .block-3 {
    margin: -100px 110px 155px;
    grid-column: 1 / span 2;
}

.collections .block-4 .banner-box a:before {
    top: 52px;
    left: auto;
    right: -195px;
    width: 335px;
    height: 340px;
}

.collections .block-4 .banner-box a img {
    top: 12px;
    left: auto;
    right: -305px;
    max-width: 95%;
}

.collections .block-5 .banner-box a:before {
    top: 50px;
    left: 122px;
    width: 300px;
    height: 222px;
}

.collections .block-5 .s-desc {
    padding-left: 60px;
    padding-right: 18%;
    min-height: 336px;
}

.collections .block-5 .banner-box a img {
    top: auto;
    bottom: -250px;
    left: 0;
}

.block-2 .banner-box .s-desc p,
.block-3 .banner-box .s-desc p {
    padding: 0 !important;
}

.shop_diamond_shape .owl-main img {
    width: 120px !important;
    margin: 0 auto;
}

.shop_diamond_shape .owl-main .content a {
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    padding-top: 8px;
    color: var(--navyBlue);
    margin: 0px 0 10px;
    display: block;
    font-weight: 500;
}

.shop_diamond_shape .owl-main .content {
    margin-top: 25px;
}

.shop_diamond_shape .owl-main .content p {
    font-size: 13px;
    padding: 5px 10px;
    line-height: 21px;
    color: #777;
    height: 50px;
}

.shop_diamond_shape .owl-main .content:before {
    position: absolute;
    width: 30px;
    height: 1px;
    content: "";
    background: #333;
    top: 0;
    left: 50%;
    margin: 0 -15px;
}

.shop_diamond_shape .owl-main .item {
    border: 1px solid var(--primary);
    padding: 55px 0;
    padding-bottom: 30px;
    border-radius: 4px;
    margin-top: 20px;
}

.shop_diamond_shape .owl-main .item:hover {
    box-shadow: -1px 2px 18px 0px rgb(10 37 56 / 28%);
}
.shop_diamond_shape .owl-nav button {
    background: rgb(255 255 255 / 95%) !important;
}
.shop_diamond_shape .owl-carousel .owl-stage {
    padding-bottom: 25px;
}

.shop_diamond_shape .owl-nav .owl-prev {
    left: -15px;
}

.shop_diamond_shape .owl-nav .owl-next {
    right: -15px !important;
}

.testimonials {
    background-color: #f1f1f1;
    padding: 60px 0;
}

.testimonials .review-block .header img {
    width: 150px;
    margin: 0 auto;
}

.testimonials .review-block .header {
    background: #fff;
    padding: 13px;
    border-bottom: 2px dotted #d4d0cf;
    box-shadow: 1px -13px 73px #e8e8e8;
}

.testimonials .review-block .body {
    background: #fff;
    /* box-shadow: 0 35px 20px #e2e2e2; */
    padding: 35px 0;
}

.testimonials .review-block .body p {
    line-height: 24px;
    color: #555;
    font-size: 15px;
    margin: 0 auto 15px;
    width: 80%;
}

.testimonials .review-block .body p span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
}

.testimonials .review-block .body p a {
    color: #555;
    transition: 0.3s all ease-in-out;
}

.testimonials .review-block .body p a:hover {
    color: #000;
}

.testimonials .review-block .body .reviews_icon img {
    width: 15px;
}

.testimonials .review-block .body .reviews_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    margin-bottom: 10px;
}

.testimonials .review-block .body h3 a {
    font-size: 18px;
    color: #333;
}

.testimonials .testimonial-icon {
    width: 45px;
    height: 40px;
    text-align: center;
    font-size: 19px;
    line-height: 40px;
    position: absolute;
    top: 33px;
    left: -19px;
    z-index: 99;
}

.testimonials .testimonial-icon {
    content: "";
    position: absolute;
    top: 30px;
    left: -18px;
}

.testimonials .google-review .testimonial-icon {
    background: #637bf7;
    color: #fff;
}

.testimonials .google-review .testimonial-icon:before {
    border-bottom: 16px solid #6278ec;
}

.testimonial-icon:before {
    content: "";
    position: absolute;
    top: -16px;
    left: 0;
    border-left: 18px solid transparent;
}

.testimonials .yelp-review .testimonial-icon {
    background: #bf1a0d;
    color: #fff;
}

.testimonials .ivouch-review .testimonial-icon {
    background-color: #330065;
    color: #FFF;
}

.testimonials .ivouch-review .testimonial-icon:before {
    border-bottom: 16px solid #330065;
}

.testimonials .yelp-review .testimonial-icon:before {
    border-bottom: 16px solid #bf1a0d;
}

.signup_section {
    background-color: #f9f0ef;
    padding: 40px 0;
}

.signup_section h3 {
    /* font-family: 'Cinzel', serif;
    font-size: 24px; */
    font-family: 'Cinzel', serif;
    font-size: 29px;
    letter-spacing: 1px;
    font-weight: 400;
}

.signup_section p {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    /* font-size: 13px; */
    color: #555;
    opacity: 0.8;
}

.signup_section p.desc-dark {
    font-size: 12px;
    line-height: 20px;
    padding-top: 12px;
    width: 70%;
    margin: 0 auto;
}

.signup_section p a {
    opacity: 1;
}

.signup_section .form .input-group input {
    height: 45px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .3);
    display: block;
    width: 100%;
    background: 0 0;
    border-radius: 50px;
    padding-left: 14px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    max-width: 335px;
}

.signup_section .form .input-group button {
    border: 0 solid #999;
    background: #ff8080;
    color: #fff;
    height: 46px;
    padding: 0 25px;
    text-transform: uppercase;
    border-radius: 50px;
    margin-left: -25px;
    z-index: 9;
}

footer {
    background: #38393c;
    padding-top: 35px;
    padding-bottom: 25px;
    font-size: 13.5px;
}

.footer-block .footer-logo {
    max-width: 120px;
    margin-bottom: 15px;
}

.footer-block p {
    color: #FFF;
    line-height: 26px;
}

.footer-block p>a {
    color: #FFF;
    text-decoration: underline;
    letter-spacing: 0.4px;
}

.footer-block h3 {
    font-size: 20px;
    color: #FFF;
    margin: 20px 0 10px;
    font-family: 'Nunito Sans' !important;
}

.footer-block ul li a {
    color: #FFF;
    line-height: 28px;
}

.footer-block ul li svg {
    fill: #ba933e;
    width: 15px;
    height: 15px;
    display: inline;
    transition: all 0.5s ease-out 0s;
}

.footer-block ul li a:hover svg {
    fill: #333;
}

.footer-block.info-block li {
    position: relative;
}

.footer-block.info-block li svg {
    position: absolute;
    top: 8px;
    left: -25px;
}

.footer-block .social-network a {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    text-align: center;
    border: 2px solid #ba933e;
    background: rgba(255, 255, 255, 0.1);
    fill: #ba933e;
    line-height: 2.2;
    transition: all 0.5s ease-out 0s;
}

.footer-block .social-network a:hover {
    background: #ba933e;
    fill: #333;
}

.footer-block .social-network {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
}

.bottom-footer p {
    opacity: 0.8;
    line-height: 24px;
    background: #eee;
    font-size: 12.5px;
    padding: 10px;
}

.footer-main-mobile .accordion-item .accordion-header button {
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 10px 15px;
    border-bottom: 1px solid #999;
    font-size: 15px;
    color: #FFF;
    font-family: 'Nunito Sans';
}

.footer-main-mobile .accordion-item .accordion-header {
    margin: 0 !important;
}

.footer-main-mobile .accordion-item .accordion-body {
    padding: 20px;
}

.footer-main-mobile .accordion-item .accordion-body,
.footer-main-mobile .accordion-item .accordion-body p,
.footer-main-mobile .accordion-item .accordion-body a {
    color: #FFF;
}

.footer-main-mobile .accordion-item .accordion-body ul li {
    line-height: 30px;
}

.footer-main-mobile li {
    position: relative;
}

.footer-main-mobile li svg {
    position: absolute;
    top: 8px;
    left: -25px;
}

.footer-main-mobile .accordion-item .accordion-header button {
    position: relative;
}

.footer-main-mobile .accordion-item .accordion-header button:before {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s transform ease-in-out;
}

.footer-main-mobile .accordion-item .accordion-header button:not(.collapsed):before {
    transform: translateY(-50%) rotateZ(180deg);
}

.footer-main-mobile .accordion-item:last-child .accordion-header button:before {
    content: '';
}

.slider-wrapper .nav-tabs {
    border: none;
    justify-content: center;
    grid-gap: 15px;
}

.slider-wrapper .nav-tabs .nav-link {
    width: 100px;
    height: 60px;
    background: #aaa;
    border-radius: 5px;
    border: none;
    position: relative;
}

.slider-wrapper .nav-tabs .nav-link.active {
    background-color: #ba933e;
}

.slider-wrapper .nav-tabs .nav-item button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 85%;
}

.slider-wrapper .nav-tabs .nav-item:first-child button:before {
    background: url('./../../../Assets/images/New York/Slider/google-reviews.jpg') no-repeat center/cover;
}

.slider-wrapper .nav-tabs .nav-item:nth-child(2) button:before {
    background: url('./../../../Assets/images/New York/Slider/yelp-reviews.jpg') no-repeat center/cover;
}

.slider-wrapper .nav-tabs .nav-item:last-child button:before {
    background: url('./../../../Assets/images/New York/ivouch-reviews.jpg') no-repeat center/cover;
}


.top-heading {
    padding: 7px 0;
    background-color: #000;
}

.top-heading marquee {
    vertical-align: middle;
    letter-spacing: 0.4px;
    font-size: 15px;
    color: #FFF;
}

.top-header {
    padding: 12px 15px;
    border-bottom: 1px solid #e8e8e8;
}

.top-header .left-content li a img,
.top-header .right-content li a img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.top-header .left-content a,
.top-header .right-content a {
    font-size: 13px;
    margin: 4px 0;
    color: #656565;
    line-height: 1.5;
    text-align: left;
}

.top-header .left-content li:first-child {
    border-right: 1px solid #ccc;
    margin-right: 10px;
    width: 125px;
}

.top-header .right-content li a {
    padding: 0 10px;
    font-size: 11px;
    color: #636363;
    display: flex;
    column-gap: 5px;
}

.main-header .navbar .navbar-nav>a {
    color: #636363;
    padding: 33px 15px;
    text-transform: uppercase;
    font-size: 13px;
    background: transparent;
    cursor: pointer;
    position: relative;
    line-height: 16px;
    transition: none;
    display: inline-block;
    text-decoration: none !important;
}

.main-header #basic-navbar-nav>.navbar-nav {
    margin-right: 30px;
}

.main-header .navbar-brand img {
    width: 135px;
    vertical-align: middle;
    padding: 10px;
}

.main-header .navbar form input {
    width: 120px;
    height: 38px;
    padding: 0 10px;
    font-size: 15px;
    color: #000;
    border: none;
    box-shadow: none;
}

.main-header .navbar form button {
    border: none;
    background-color: transparent;
}

.main-header .navbar form button svg {
    fill: #ddd;
    width: 19px;
    height: 19px;
}

.main-header .navbar form {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
}

.fixed-header {
    position: fixed !important;
    width: 100%;
    z-index: 998;
    background: #ffffff;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e8e8e8;
}

.main-header .navbar .navbar-nav>a:before {
    content: '' !important;
    display: block !important;
    width: 0 !important;
    height: 4px !important;
    transition: .1s width ease !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
}

.main-header .navbar .navbar-nav>a:hover:before {
    width: 100% !important;
    background-color: #ba933e !important;
}

/* 
.mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 9999;
    background: #FFF;
    height: 100%;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-out;
    overflow-y: auto;
}

.mobile-menu.nav-open {
    opacity: 1;
    visibility: visible;
    left: 0;
}

.mobile-menu .auth {
    background-color: #f6f6f6;
    border-radius: 50%;
    margin-right: 15px;
    width: 40px;
    display: block;
    height: 40px;
    text-align: center;
}

.mobile-menu .auth img {
    width: 16px;
    height: 16px;
}

.mobile-menu button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 10px;
    position: relative;
}

.mobile-menu button a {
    font-size: 12px;
    color: #000;
    line-height: 40px;
}

.mobile-menu button {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans' !important;
    text-transform: uppercase;
}

.mobile-menu .accordion .accordion-item:first-child button a {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

.mobile-menu .accordion .accordion-item {
    border-bottom: 1px solid #e5e5e5 !important;
}

.mobile-menu .accordion .accordion-item .accordion-header {
    line-height: inherit;
    max-height: fit-content;
    display: flex;
    height: 55px;
    margin: 0;
}

.mobile-menu .accordion .accordion-item .accordion-body {
    box-shadow: inset 0px 11px 8px -10px #ccc !important;
}

.mobile-menu .accordion-item .accordion-header button:before {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    transition: 0.3s transform ease-in-out;
}

.mobile-menu .accordion-item .accordion-header button:not(.collapsed):before {
    transform: translateY(-50%) rotateZ(180deg);
}

.mobile-menu .accordion .accordion-item:nth-child(n+6) button:before {
    content: '';
}

.overlay {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: fixed;
    display: block;
    top: 0px;
    z-index: 999;
    right: 0px;
    height: 100%;
    transition: 0.2s all ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.overlay.open {
    visibility: visible;
    opacity: 1;
}

.mobile-menu .accordion-body h2.title {
    margin: 8px 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Nunito Sans' !important;
    padding: 10px 15px 0;
}

.mobile-menu .accordion-body h2.title:nth-child(2n) {
    padding-bottom: 0;
    margin-top: 0;
}

.mobile-menu .accordion-body ul:nth-child(2n) {
    padding-bottom: 0;
}

.mobile-menu .two-col-list {
    column-count: 2;
}

.mobile-menu ul {
    padding: 0px 15px 10px;
}

.mobile-menu ul li a {
    font-size: 11px;
    line-height: normal;
    padding: 4px 0;
    color: #555;
    display: block;
}

.mobile-menu ul li a img {
    width: 27px;
    height: 27px;
    object-fit: contain;
    margin-right: 12px;
}

.mobile-menu .accordion-body {
    background-color: #f6f6f6;
}

.mobile-menu .weddingRings ul li a img {
    width: 40px;
    margin-right: 5px;
}

.mobile-menu .fine_jwel ul li h2 {
    padding: 0 !important;
    margin: 0 !important;
}

.mobile-menu .fine_jwel .accordion-body {
    padding-top: 10px;
}

.mobile-menu .fine_jwel ul li > ul {
    padding: 0 !important;
}

.mobile-menu .contactDetail ul li span {
    background: #666;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    line-height: 35px;
    margin: 0 0 5px;
}

.mobile-menu .contactDetail ul li span svg {
    fill: #FFF;
    width: 15px;
    height: 15px;
}

.mobile-menu .contactDetail ul li a {
    text-align: center;
    width: 50px;
}

.mobile-menu .contactDetail ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu .contactDetail h2 {
    height: auto !important;
    padding-top: 15px;
}

.mobile-menu .contactDetail h2 button {
    text-align: center;
}

.mobile-menu .accordion .accordion-item:last-child {
    border: none !important;
} */

.testimonials .owl-dots {
    bottom: -25px;
}

.testimonials .owl-nav .owl-prev {
    left: 10px;
}

.testimonials .owl-nav .owl-next {
    right: 10px;
}

.owl-nav button::before {
    font-family: 'FontAwesome';
    position: absolute;
    top: 46%;
    left: 68%;
    transform: translate(-50%, -30%);
    width: 100%;
    height: 100%;
    color: #000;
}

.owl-nav button.owl-prev::before {
    content: '';
    background-image: url('../../images/right.png');
    background-repeat: no-repeat;
    background-size: 71%;
}

.owl-nav button.owl-next::before {
    content: '';
    background-image: url('../../images/left.png');
    background-size: 71%;
    background-repeat: no-repeat;
}

.navbar.navbar-expand-lg {
    border-bottom: 1px solid #e8e8e8;
}

.mega-dropdown-menu {
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    z-index: 9;
    border-top: 1px solid #ddd;
}

.content-mega-menu {
    min-height: 300px;
    background-color: #FFF;
}

.overlay-mask {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgb(0 0 0 / 30%);
    z-index: -1;
}

.mega-dropdown-menu .row div[class*="col-"] {
    padding: 20px;
    border-right: 1px solid #ddd;
}

.mega-dropdown-menu .row div[class*="col-"]:nth-last-child(2),
.mega-dropdown-menu .row div[class*="col-"]:last-child {
    border: none;
}

.mega-dropdown-menu .content-mega-menu {
    width: 100%;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block>h2.title,
.mega-dropdown-menu .content-mega-menu .mega-menu-block>h2.title a {
    font-family: 'Nunito Sans' !important;
    font-size: 15px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #000;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block a:hover {
    color: #bf1a0d !important;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block .two-col-list {
    column-count: 2;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block a {
    font-size: 14px;
    color: #555;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block li {
    margin-bottom: 5px;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block a img {
    max-width: 25px;
    margin-right: 8px;
}

.mega-dropdown-menu .content-mega-menu img.right-image {
    min-width: 250px;
}

.main-header .navbar .navbar-nav>a:hover+.mega-dropdown-menu,
.mega-dropdown-menu:hover {
    display: block !important;
}

.mega-dropdown-menu .content-mega-menu .mega-menu-block.band-column a img {
    max-width: 60px;
}

.best_selling_product .nav-tabs {
    justify-content: center;
    margin-top: 150px;
    border: none;
}

.best_selling_product .nav-tabs button {
    background: -webkit-linear-gradient(left, #50556b, #786266 65%);
    background-position: right 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    color: #444344;
    padding-top: 20px;
    border: none;
    width: 100%;
}

.best_selling_product .nav-tabs li {
    width: 150px;
}

.best_selling_product .nav-tabs li button {
    position: relative;
    padding: 0;
}

.best_selling_product .nav-tabs li button:before {
    content: '';
    position: absolute;
    top: -130px;
    left: 50%;
    transform: translateX(-50%);
    width: 110px;
    background: #fff;
    display: inline-block;
    padding: 30px;
    box-shadow: inset 0 -16px 35px rgba(93, 102, 114, .18);
    height: 110px;
    border-radius: 50%;
    transition: all 0.5s ease-out;
}

.best_selling_product .nav-tabs li button:hover:before {
    transition: all .4s;
    transform: translateX(-50%) scale(1.08);
}

.best_selling_product .nav-tabs li button:after {
    content: '';
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
}

.best_selling_product .nav-tabs li button.active:after {
    /* transform: translateX(-120px);
    filter: drop-shadow(95px 0 0 #FFF); */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(163deg) brightness(103%) contrast(103%);
}

.best_selling_product .nav-tabs li:first-child button:after {
    background: url('../../images/tabs/ring.svg') no-repeat center/contain;
}

.best_selling_product .nav-tabs li:nth-child(2) button:after {
    background: url('../../images/tabs/band.svg') no-repeat center/contain;
}

.best_selling_product .nav-tabs li:nth-child(3) button:after {
    background: url('../../images/tabs/earings.svg') no-repeat center/contain;
}

.best_selling_product .nav-tabs li:nth-child(4) button:after {
    background: url('../../images/tabs/brace.svg') no-repeat center/contain;
}

.best_selling_product .nav-tabs li:last-child button:after {
    background: url('../../images/tabs/nack.svg') no-repeat center/contain;
}

.best_selling_product .nav-tabs li button.active:before {
    background-color: #30d5c8;
}

.best_selling_product .owl-item a p {
    color: #333;
    font-size: 14px;
    font-weight: 600;
}

.best_selling_product .owl-item a p:last-child {
    font-size: 18px;
    margin: 0;
    margin-top: 8px;
}

.best_selling_product {
    background: #f4f4f4;
    padding: 45px 0 30px;
    margin-bottom: 70px;
}

.best_selling_product .owl-item .item {
    background: #FFF;
    padding: 15px;
    border-radius: 10px;
}

.best_selling_product .owl-nav .owl-prev {
    left: -30px;
}

.best_selling_product .owl-nav .owl-next {
    right: -30px;
}

.top-heading {
    background-color: #FFB5A5;
}

.top-heading marquee {
    color: #000;
    font-weight: 600;
}

.showacase_mobile_login svg.rcs_cart_mobile_icon1.ml-2 {
    /* color: #636363; */
    fill: var(--navyBlue);
}

.rcs_header svg {
    fill: #fff;
}

.rcs_top_header {
    padding: 8px 0;
    background-color: #000;
}

.rcs_top_head_content_right ul li {
    font-size: 14px !important;
    font-weight: 600;
}

.rcs_top_head_content_right ul li:last-child {
    border-right: none !important;
}

.rcs_top_head_content_right ul li a,
.rcs_top_head_content_vright a {
    color: #FFF !important;
    font-size: 14px;
}

.rcs_top_head_content_vright a svg {
    font-size: 20px;
}

.mg_main_d {
    justify-content: space-between !important;
}

.rcs_search_bar form span {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    left: auto !important;
    right: 5px !important;
    margin: auto 0;
    background: #676767;
    color: #FFF;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 100px;
}

.rcs_search_bar form span svg {
    position: static !important;
}

/*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\new_york theme css\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
.ny_create span {
    font-family: var(--font_1);
    font-size: 30px;
    text-transform: uppercase !important;
    line-height: 54px;
}

.ny_create h1 {
    font-size: 54px;
    text-transform: uppercase !important;
    font-family: initial;
}

.ny_custumcontainer {
    max-width: 1200px;
    position: relative;
    width: 100%;
}

.bg_banner {
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 850px;
    justify-content: center;
    justify-content: left;
    vertical-align: middle;
}

.ny_banner_content h1 {
    color: #fff;
    text-transform: capitalize;
    font-size: 55px;
    margin-bottom: 10px;
    font-family: var(--font_2);
}

.ny_banner_content p {
    color: var(--white);
    font-size: 18px;
}

.ny_twobuttons {
    display: flex;
}

.ny_banner_content button.btn.btn-dark {
    text-transform: uppercase;
    /* width: 22%; */
    /* height: 45px; */
    border-radius: 0;
    font-size: 16px;
    background-color: #0000;
    padding: 10px 25px;
    border-color: var(--white);
}

button.owl-dot.active {
    background: #ff8080 !important;
}

/* .macau_banner_slider div {
    background-size: cover;
    height: 736px;
    background-repeat: no-repeat;
} */
.megamenu_Showcase_top_header__rT3Zi {
    background-color: #38393c !important;
}

/* .rcs_right_side ul li svg {
    color:#636363!important;
} */
.slick-dots li.slick-active button:before {
    /* opacity: .75; */
    /* color: black; */
    /* border: 4px solid #222 !important; */
    /* background: #dc9f9f; */
}

/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

@media (min-width: 320px) and (max-width:767px) {
    .ny_yel {
        margin-top: 30px;
    }

    .bg_banner {
        height: 252px;
    }

    .ny_banner_content h1 {
        font-size: 30px;
        /* margin-bottom: 2px; */
        color: #ffff;
    }

    .ny_create span {
        font-size: 21px;
    }

    .ny_banner_content {
        text-align: center;
    }

    .ny_banner_content p {
        display: none;
    }

    .ny_create h1 {
        font-size: 30px
    }

    .macau_banner_slider .slick-dots {
        bottom: 1px;
    }

    .ny_banner_content button.btn.btn-dark {
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 20px;
        padding: 8px 20px;
    }

    .ny_twobuttons {
        display: grid;
    }

    .signup_section h3 {
        font-size: 24px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .ny_custumcontainer {
        max-width: 720px;
    }

    .ny_banner_content button.btn.btn-dark {
        width: 32%;
        font-size: 14px;
    }

    .macau_banner_slider .slick-dots {
        bottom: 9px;
    }

    .ny_banner_content h1 {
        font-size: 35px;
        margin-bottom: 10px;
    }

    .bg_banner {
        height: 306px;
    }

    .ny_twobuttons {
        display: grid;
    }

    form.gs_news_form.w-100 .input-group {
        display: flex;
    }

    .outline-btn {
        height: 47px;
        padding: 13px 0px;
    }

    .ny_create h1 {
        font-size: 42px;
    }

    .ny_create span {
        font-size: 24px;
        line-height: 32px;
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .bg_banner {
        background-size: cover;
        height: 306px;
    }

    .macau_banner_slider .slick-dots {
        bottom: 14px;
    }

    .ny_custumcontainer {
        max-width: 960px;
    }

    .outline-btn {
        font-size: 13px;
    }
}

/* %150 */
@media (min-width:1280px) and (max-width:1439px) {
    .bg_banner {
        height: 550px;
    }

    .megamenu_showcase_center_header__EiiCj .megamenu_showcase_center_header_left__2Ym3m ul li {
        font-size: 11px !important;
    }

    .megamenu_Showcase_top_header__rT3Zi .megamenu_Showcase_top_head_content__EFYS8 .megamenu_Showcase_top_head_text__FaEhU {
        font-family: 400;
    }

    .shop_diamond_shape .owl-main img {
        width: 80px !important;
    }
}

/* 110% to 133%  */
@media (min-width:1440px) and (max-width:1900px) {
    .bg_banner {
        height: 650px;
    }
}












@media only screen and (max-width: 1366px) {
    .mega-dropdown-menu .content-mega-menu .mega-menu-block a img {
        max-width: 20px;
    }

    .mega-dropdown-menu .row div[class*="col-"] {
        padding: 20px 15px;
    }

    .gemstone-megamenu .content-mega-menu .row>.col-md-2 {
        min-width: 24%;
    }

    .gemstone-megamenu .content-mega-menu .row>.col-md-4 {
        max-width: 25%;
    }

    .mega-dropdown-menu .content-mega-menu .mega-menu-block.band-column a img {
        max-width: 35px;
    }
}

@media only screen and (max-width: 1024px) {
    .banner-slider .owl-stage img {
        height: 350px;
    }

    .collections .banner-block {
        width: 380px;
    }

    .collections .block-3 .banner-box a img {
        top: 10px;
        left: -190px;
    }

    .collections .block-4 .banner-box a:before {
        top: 60px;
        width: 280px;
        height: 280px;
        right: -150px;
    }

    .collections .banner-box img {
        top: 0px;
        right: -210px;
    }

    .collections {
        grid-template-columns: repeat(2, 550px);
    }

    .collections .banner-box .s-desc p {
        margin: 0;
    }

    .collections .block-3 {
        margin: -150px 130px 60px;
    }

    .collections .block-2 .s-desc {
        min-height: 410px;
    }

    .collections .block-4 .banner-box a img {
        right: -240px;
        max-width: 100%;
    }

    .collections .block-5 .banner-box a:before {
        left: 130px;
        width: 270px;
        height: 270px;
    }

    .collections .block-5 .banner-box a img {
        bottom: -200px;
    }

    .collections .block-2 .banner-box a:before {
        bottom: -170px;
        left: 40px;
        width: 300px;
        height: 300px;
    }

    .main-header .navbar .navbar-nav>a {
        font-size: 10px;
    }

    .banner-slider .owl-stage .content {
        width: 80%;
        transform: translate(-50%, -70%);
    }

    .banner-slider .owl-stage .content h2 {
        font: 400 48px/normal 'Great Vibes' !important;
    }

    .outline-btn {
        width: 60%;
    }

    .main-header .navbar .navbar-nav {
        grid-gap: 20px;
    }

    .collections .block-2 .banner-box img {
        bottom: -220px;
    }

    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }

    .navbar-expand-lg .navbar-toggler {
        display: block !important;
    }

    .slider-wrapper .tab-content {
        grid-template-columns: repeat(2, 370px) !important;
    }

    .rcs_footer_content_one_social_icn ul {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    /* .logo img {
        width: 100% !important;
    } */
}

@media only screen and (max-width: 992px) {
    .outline-btn {
        width: 100%;
    }

    .create_own_diamond_ring img {
        width: 100%;
    }

    .create_own_diamond_ring {
        margin: 30px 0 30px;
    }

    .collections .banner-box .s-desc {
        padding: 50px 30px 10px;
        min-height: 280px;
    }

    .collections .banner-block {
        width: 300px;
    }

    .collections .banner-box .s-desc p {
        padding-right: 20px;
    }

    .collections .block-3 .banner-box .s-desc {
        padding-left: 25%;
    }

    .collections .block-3 .banner-box a img {
        top: 50px;
        left: -110px;
        max-width: 70%;
    }

    .collections .block-4 .banner-box a img {
        right: -150px;
        max-width: 80%;
        min-width: 250px;
        top: 120px;
    }

    .collections .block-2 .s-desc {
        min-height: 320px;
    }

    .collections .block-3 {
        margin: -130px 50px 60px;
    }

    .collections .block-2 .banner-box a:before {
        bottom: -130px;
        left: 40px;
        width: 220px;
        height: 220px;
    }

    .collections .block-2 .banner-box img {
        bottom: -170px;
        max-width: 290px;
    }

    .collections .block-5 .banner-box a img {
        bottom: -120px;
        max-width: 280px;
        left: 10px;
    }

    .collections .block-5 .banner-box a:before {
        left: 10px;
        width: 90%;
        height: 90%;
        top: 30px;
    }

    .collections {
        grid-template-columns: repeat(2, 390px);
    }

    .collections .banner-box .s-desc h3 {
        font-size: 24px;
        line-height: 35px;
    }

    .collections .banner-box .s-desc p {
        font-size: 14px;
    }

    .collections .banner-box img {
        max-width: 60%;
        right: -80px;
    }

    .collections .banner-box a:before {
        right: -50px;
        width: 290px;
        height: 210px;
    }

    .collections .block-3 .banner-box a:before {
        left: -60px;
        width: 230px;
        height: 230px;
    }

    .shop_diamond_shape .owl-main .content:before,
    .shop_diamond_shape .owl-main .content p {
        display: none;
    }

    .shop_diamond_shape .owl-main .content {
        margin-top: 15px;
    }

    .shop_diamond_shape .owl-main .item {
        border: none;
        padding: 0;
    }

    .shop_diamond_shape>.container {
        padding: 0;
        max-width: 100%;
    }

    .slider-wrapper {
        grid-template-columns: repeat(2, 300px);
        grid-gap: 40px;
    }

    .banner-slider .owl-stage .content h2 {
        font: 400 40px/normal 'Great Vibes' !important;
    }

    .banner-slider .owl-stage .content p {
        font-size: 15px;
    }

    /* .banner-slider .owl-stage .content {
        transform: translate(-68%, -80%);
    } */

    .top-header {
        padding: 12px 0;
    }

    .navbar-light .navbar-toggler {
        border: none;
    }

    .navbar-light .navbar-brand {
        margin: 0;
    }

    .slider-wrapper .tab-content {
        grid-template-columns: repeat(1, 90%) !important;
    }

    .navbar .navbar-collapse {
        display: none;
    }

    .create_own_diamond_ring .right-content h2 span {
        font-size: 25px;
    }

    .collections .block-4 .banner-box a:before {
        top: 30px;
        width: 90%;
        height: 80%;
        right: 40px;
    }

    .main-header .navbar-brand img {
        width: 110px;
    }

    .best_selling_product {
        display: none;
    }

    section.rcs_mobile_footer_section {
        background-color: var(--navyBlue) !important;
    }
}

@media only screen and (max-width: 600px) {
    section.rcs_mobile_footer_section {
        background-color: var(--navyBlue);
    }



    .top-header {
        height: 70px;
    }

    section.testimonials li.nav-item {
        display: none;
    }

    .banner-slider .owl-stage img {
        height: 310px;
    }

    .banner-slider .owl-stage .content p {
        display: none;
    }

    .solid-black {
        margin-top: 5px;
        padding: 7px 15px;
        min-width: 160px;
        font-size: 12px;
    }

    .banner-slider .owl-stage .content h2 {
        font: 400 30px/normal 'Great Vibes' !important;
        color: #FFF;
    }

    .banner-slider .owl-stage .content {
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        height: 100%;
        background: rgb(116 116 116 / 50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .create_own_diamond_ring .right-content h2 {
        text-align: center;
        font-size: 25px;
    }

    .create_own_diamond_ring .right-content h2 span {
        font-size: 25px;
        line-height: 30px;
    }

    /* .create_own_diamond_ring .row {
        width: 100% !important;
    } */

    .create_own_diamond_ring .right-content {
        text-align: center;
    }

    .outline-btn {
        width: 100%;
        margin: 10px 0;
    }

    .create_own_diamond_ring img {
        width: 70%;
        margin: 0 auto;
    }

    .create_own_diamond_ring .right-content p {
        margin: 5px 20px 20px;
    }

    .title-box h2 {
        font-size: 24px;
        margin-bottom: 5px;
    }

    .collections {
        grid-template-columns: repeat(1, 100%);
    }

    .collections .block-2 {
        width: 90% !important;
        margin: 50px auto 0;
    }

    .collections .block-3 {
        width: 90%;
        margin: 200px auto 60px;
        grid-column: 1;
    }

    .collections .block-2 .s-desc {
        min-height: 300px;
        text-align: center;
    }

    .collections .block-2 .banner-box img {
        left: 30px;
    }

    .collections .block-2 .banner-box a:before,
    .collections .block-3 .banner-box a:before,
    .collections .block-4 .banner-box a:before,
    .collections .block-5 .banner-box a:before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
    }

    .collections .block-3 .banner-box .s-desc {
        padding-left: 25%;
        text-align: right;
    }

    .collections .block-3 .banner-box a img {
        top: 150px;
        left: -30px;
    }

    .collections .banner-block {
        width: 90%;
    }

    .collections .banner-block.block-4 {
        margin: 50px auto 0;
    }

    .collections .block-4 .banner-box a img {
        right: -20px;
        max-width: 70%;
        min-width: 200px;
        top: 150px;
    }

    .collections .banner-block.block-5 {
        margin: 120px auto 0;
        text-align: center;
    }

    .collections .block-5 .banner-box a img {
        bottom: -130px;
        max-width: 300px;
        left: 20px;
    }

    .collections .block-5 .s-desc p {
        padding: 0;
    }

    .collections .banner-block.block-1 {
        width: 80% !important;
    }

    .shop_diamond_shape .owl-main img {
        width: 85px !important;
    }

    .shop_diamond_shape .owl-main .content {
        margin-top: 0px;
    }

    .signup_section p.desc-dark {
        width: 100%;
        padding-top: 15px;
    }

    .signup_section .form .input-group input {
        border-radius: 100px !important;
    }

    .signup_section .form .input-group button {
        margin-top: 15px;
        margin-left: 0;
    }

    .footer-block .social-network {
        justify-content: center;
    }

    .slider-wrapper .review-block {
        width: 90%;
        margin: 0 auto;
    }

    .top-header .right-content li a {
        padding: 0 5px;
    }

    .top-header {
        border-bottom: none;
    }

    .navbar-light .navbar-toggler {
        padding: 0;
        position: absolute;
        top: -70px;
    }

    .fixed-header {
        position: static !important;
    }

    .navbar-light .navbar-brand {
        position: absolute;
        top: -75px;
        left: 50%;
        transform: translateX(-50%);
    }

    .main-header {
        padding: 10px 0;
    }

    .main-header .navbar form,
    .main-header .navbar form input {
        width: 100%;
    }

    .collections .banner-box a:before {
        right: auto;
        width: 90%;
        height: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }



    .rcs_top_head_content_right ul {
        justify-content: flex-start !important;
    }

    .rcs_top_head_content_right ul li:first-child,
    .rcs_top_head_content_right ul li span,
    .rcs_top_head_content {
        padding: 0 !important;
    }




}

@media only screen and (max-width: 1440px) and (min-width: 1280px) {
    .top-heading {
        padding: 0 0 3px;
    }

    .top-heading marquee {
        font-size: 12px;
    }

    .rcs_top_head_content_right ul li {
        font-size: 12px !important;
    }

    .rcs_top_head_content_right ul li a,
    .rcs_top_head_content_vright a {
        font-size: 12px;
    }

    .rcs_top_head_content_vright a svg {
        font-size: 14px;
    }

    .rcs_top_head_content div[class*="col-"] {
        display: flex;
        align-items: center;
    }

    .rcs_top_header {
        padding: 0;
    }

    .rcs_top_head_content div[class*="col-"]:last-child {
        justify-content: flex-end;
    }



    .rcs_search_bar form span {
        width: 26px;
        height: 26px;
        line-height: 26px;
    }

    .rcs_search_bar svg:first-child {
        font-size: 16px;
    }

    .rcs_header {
        padding: 10px 0px;
    }

    #rcs_menu_header_top ul li {
        padding-top: 5px !important;
    }

    #rcs_menu_header_top ul h6 a {
        height: 37px;
        padding-bottom: 5px !important;
    }


    .banner-slider .owl-stage .content h2 {
        font: 400 48px/normal 'Great Vibes' !important;
    }

    .banner-slider .owl-stage .content p {
        font-size: 16px;
    }

    .solid-black {
        padding: 8px 10px;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
    .ny_banner_content h1 {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .ny_banner_content p {
        font-size: 16px;
    }

    .bannerSliderHome.macau_banner_slider button.slick-arrow.slick-prev::before {
        left: 3px;
        margin: auto;
        top: 13px;
        background-size: 85%;
    }

    .bannerSliderHome.macau_banner_slider button.slick-arrow.slick-next::before {
        top: 10px;
        background-size: 62%;
    }


}

@media only screen and (max-width: 1366px) and (min-width: 1200px) {
    .collections .banner-box img {
        max-width: 85%;
    }

    .collections .block-3 .banner-box a img {
        top: -35px;
        left: -150px;
    }

    .collections .block-5 .banner-box a img {
        top: auto;
        bottom: -200px;
        left: 40px;
    }

    .collections .block-2 .banner-box img {
        left: 40px;
        bottom: -200px;
        top: auto;
    }
}

@media only screen and (min-width: 601px) {
    .slider-wrapper .tab-content {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(1, 100%);
        grid-gap: 40px;
    }

    .slider-wrapper .nav.nav-tabs {
        display: none;
    }

    .slider-wrapper .tab-content>.tab-pane {
        display: block;
    }
}

@media only screen and (min-width: 1200px) {
    .rcs_custom_home_container.container {
        /* max-width: 1750px; */
    }
}

@media only screen and (min-width: 600px) {

    #uncontrolled-tab-example-tabpane-yelp,
    #uncontrolled-tab-example-tabpane-ivouch {
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {

    .collections .banner-box .s-desc {
        padding: 15px 14px;
        min-height: 160px;
        width: 100%;
        text-align: center;
        margin-bottom: 200px;
    }

    .collections .banner-box a:hover img {
        transform: translateX(-50%) translateY(0) !important;
    }

    .collections .block-1 {
        margin-right: 0;
    }

    .collections .banner-block.block-1,
    .collections .banner-block {
        width: 100% !important;
    }

    .collections .banner-box .s-desc h3 {
        margin: 20px 0 30px;
    }

    .collections .banner-box .s-desc p {
        font-size: 13px;
        padding: 0;
        margin-bottom: 20px;
    }

    .collections .banner-box img {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        top: 175px;
    }

    .collections .banner-box a:before {
        width: 80%;
        height: 200px;
        transform: translate(-50%, 35%);
    }

    .collections .block-2 {
        width: 100% !important;
        margin: 0px auto 0;
    }

    .collections .block-2 .banner-box img {
        left: 50%;
        width: 60% !important;
        bottom: auto;
        top: 150px;
    }

    .collections .block-2 .banner-box a:before,
    .collections .block-3 .banner-box a:before,
    .collections .block-4 .banner-box a:before,
    .collections .block-5 .banner-box a:before {
        transform: translate(-50%, 35%);
        width: 80%;
        height: 200px;
    }

    .collections .block-3 .banner-box .s-desc {
        padding-left: 15px;
        text-align: center;
        margin-top: 20px;
    }

    .collections .block-3,
    .collections .banner-block.block-4,
    .collections .banner-block.block-5 {
        margin: 20px auto 0;
    }

    .collections .banner-block.block-4 .banner-box .s-desc {
        margin-bottom: 190px;
    }

    .collections .block-3 .banner-box a img,
    .collections .block-4 .banner-box a img,
    .collections .block-5 .banner-box a img {
        left: 50%;
        max-width: 60%;
        right: auto;
        min-width: auto;
    }

    .collections .block-4 .banner-box a img {
        top: 180px;
    }

    .collections .block-5 .banner-box a img {
        bottom: -180px;
    }

    .banner-block.block-1 .banner-box .s-desc {
        margin-bottom: 240px;
    }

    .our_collection {
        padding: 50px 0 50px;
    }

    .shop_diamond_shape .owl-carousel .owl-stage {
        padding-bottom: 5px;
    }

}

@media(max-width: 650px) {
    .insta-iframe {
        height: 210px;
    }
}

@media(min-width: 650px) and (max-width:1200px) {
    .insta-iframe {
        height: 210px;
    }
}

@media(min-width: 1210px) {
    .insta-iframe {
        height: 390px;
    }

    .bg_banner {
        background-position: right;
    }

}

.mobile-menu .accordion .accordion-item:nth-child(6) .accordion-header button:before {
    display: none;
}


/* Explore Our Collection */

.ExploreOurCollection {
    background: #EBF4FF;
    padding: 70px 0 40px;
}
.ExploreOurCollection_box h3 {
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    color: #000;
}

.ExploreOurCollection_box p {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #000;
}

.ExploreOurCollection_box {
    display: block;
    background: #fff;
    box-shadow: 0px 4px 20px 0px #00000026;
    max-width: 833px;
    /* margin: auto; */
    padding: 40px;
    margin: 50px 0;
    margin-left: 20px;
}

.ExploreOurCollection_box_text {
    padding-right: 100px;
    min-width: 500px;
}

.ExploreOurCollection_innerbox {
    display: flex;
    align-items: center;
    position: relative;
    left: 120px;
}

.ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox {
    flex-direction: row-reverse;
    left: -120px;
}
.ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox .ExploreOurCollection_box_text {
    padding-left: 100px;
    padding-right: 0;
}

.ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) {
    margin-left: auto;
    margin-right: 30px;
}

@media (min-width:992px) and (max-width:1199px) {
    .ExploreOurCollection_box_text{
        min-width: 400px;
    }
    .ExploreOurCollection_innerbox{
        left: 60px;
    }
    .ExploreOurCollection_box{
        max-width: 700px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox{
        left: -60px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) {
        margin-right: 10px;
    }
}
@media (min-width:769px) and (max-width:991px) {
    .ExploreOurCollection_box_text{
        min-width: 300px;
    }
    .ExploreOurCollection_innerbox {
        left: 80px;
    }
    .ExploreOurCollection_box{
        max-width: 650px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox{
        left: -80px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) {
        margin-right: 0px;
    }
    .ExploreOurCollection_innerbox img {
        max-width: 100%;
        width: 50%;
    }
    .ExploreOurCollection_box h3 {
        font-size: 30px;
    }
    .ExploreOurCollection_box p {
        font-size: 14px;
        line-height: 25px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox .ExploreOurCollection_box_text {
        padding-left: 50px;
        padding-right: 0;
    }
}
@media (max-width:768px) {
    .ExploreOurCollection {
        padding: 50px 0;
    }
    .ExploreOurCollection_innerbox {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        left: 0;
    }
    .ExploreOurCollection_box_text {
        padding-right: 0px;
        min-width: 100%;
    }
    .ExploreOurCollection_innerbox img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
    }
    .ExploreOurCollection_box h3 {
        font-size: 30px;
        white-space: normal;
    }
    .ExploreOurCollection_box, .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd){
        margin: 20px;
        padding: 20px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox {
        flex-direction: column-reverse;
        left: 0px;
    }
    .ExploreOurCollection .ExploreOurCollection_box:nth-child(odd) .ExploreOurCollection_innerbox .ExploreOurCollection_box_text{
        padding: 0;
    }
}